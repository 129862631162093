import styled from '@emotion/styled'

export const WorksheetList = styled.ul``

export const WorksheetItem = styled.li`
  list-style-type: none;
`

export const WorksheetLink = styled.a``

export const WorksheetDisplay = styled.h3`
  padding-bottom: 0.5rem;
`
