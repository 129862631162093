import React from 'react'
import { Helmet } from 'react-helmet'
import { Global } from '@emotion/react'
import { useTranslation } from 'react-i18next'

import GlobalStyles from 'components/styleComponents/GlobalStyles'
import {
  StripeCheckoutRedirectContainer,
  ShapeContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectHeader,
  StripeCheckoutRedirectDescription,
} from 'components/styleComponents/StripeCheckout'
import {
  WorksheetItem,
  WorksheetLink,
  WorksheetDisplay,
  WorksheetList,
} from 'components/styleComponents/WorksheetComponents'
import LottieShape from 'components/General/LottieShape'
import AbstractDownloadsAnimation from 'components/General/Animations/AbstractDownloadsAnimation'

// If we hosted these files at our domain, we could use download attribute on link but cross-origin download is not supported
const WORKSHEET_INFO = [
  {
    id: 'lesson0',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L2_Greetings_Worksheet.pdf',
    display: 'Greetings',
  },
  {
    id: 'lesson1',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L3_Onboarding_Worksheet.pdf',
    display: 'Onboarding',
  },
  {
    id: 'lesson2',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L4_Family_Worksheet.pdf',
    display: 'Family',
  },
  {
    id: 'lesson3',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L5_Counting_Worksheet.pdf',
    display: 'Counting',
  },
  {
    id: 'lesson4',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L6_Directions_Worksheet.pdf',
    display: 'Directions',
  },
  {
    id: 'lesson5',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L7_Time_Worksheet.pdf',
    display: 'Time',
  },
  {
    id: 'lesson6',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L8_Food_Worksheet.pdf',
    display: 'Food',
  },
  {
    id: 'lesson7',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L9_Supermarket_Worksheet.pdf',
    display: 'Supermarket',
  },
  {
    id: 'lesson8',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L10_Shopping_for_Clothes_Worksheet.pdf',
    display: 'Shopping for_Clothes',
  },
  {
    id: 'lesson9',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L11_Job_Interview_Worksheet.pdf',
    display: 'Job Interview',
  },
  {
    id: 'lesson10',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L12_The_Movies_Worksheet.pdf',
    display: 'The Movies',
  },
  {
    id: 'lesson11',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L13_Gas_Station_Worksheet.pdf',
    display: 'Gas Station',
  },
  {
    id: 'lesson12',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L14_The_Holidays_Worksheet.pdf',
    display: 'The Holidays',
  },
  {
    id: 'lesson13',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L15_Hobbies_Worksheet.pdf',
    display: 'Hobbies',
  },
  {
    id: 'lesson14',
    url:
      'https://fwamedia.s3.us-west-2.amazonaws.com/fwa1/worksheets/FWA1_L16_Celebration_Night_Worksheet.pdf',
    display: 'Celebration Night',
  },
]

const Worksheets = () => {
  const { t, ready } = useTranslation('uniminutoWorksheetsPage')

  const worksheetElements = WORKSHEET_INFO.map((worksheet) => {
    return (
      <WorksheetItem key={worksheet.id}>
        <WorksheetLink href={worksheet.url} target="_blank">
          <WorksheetDisplay>{worksheet.display}</WorksheetDisplay>
        </WorksheetLink>
      </WorksheetItem>
    )
  })

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <Global styles={GlobalStyles} />
      <main>
        {!ready ? null : (
          <StripeCheckoutRedirectContainer>
            <ShapeContainer>
              <LottieShape
                size={500}
                animationData={AbstractDownloadsAnimation}
              />
            </ShapeContainer>
            {!ready ? null : (
              <StripeCheckoutContentContainer>
                <StripeCheckoutRedirectHeader>
                  {t('header')}
                </StripeCheckoutRedirectHeader>
                <StripeCheckoutRedirectDescription>
                  <WorksheetList>{worksheetElements}</WorksheetList>
                </StripeCheckoutRedirectDescription>
              </StripeCheckoutContentContainer>
            )}
          </StripeCheckoutRedirectContainer>
        )}
      </main>
    </>
  )
}

export default Worksheets
